import '../scss/main.scss';
import '../index.html';

(function () {
    encryptEmail();
    bindUI();
}())

function encryptEmail() {
    var contactButton = document.querySelector('.js-contact');
    contactButton.setAttribute('href', rot13(contactButton.getAttribute('href')));
}

function rot13(str) {
    var input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
    var index = x => input.indexOf(x);
    var translate = x => index(x) > -1 ? output[index(x)] : x;
    return str.split('').map(translate).join('');
}

function bindUI() {
    var dataPrivacyContainer = document.querySelector('.js-data-privacy-container');
    var dataPrivacyButton = document.querySelector('.js-data-privacy-button');
    var impressumContainer = document.querySelector('.js-impressum-container');
    var impressumButton = document.querySelector('.js-impressum-button');

    impressumButton.addEventListener('click', function(event) {
        event.preventDefault();
        if (impressumContainer.classList.contains('hide')) {
            impressumContainer.classList.remove('hide');
        } else {
            impressumContainer.classList.add('hide');
        }
    });
    dataPrivacyButton.addEventListener('click', function(event) {
        event.preventDefault();
        if (dataPrivacyContainer.classList.contains('hide')) {
            dataPrivacyContainer.classList.remove('hide');
        } else {
            dataPrivacyContainer.classList.add('hide');
        }
    });
}